.BuyNow {
  background-color: rgba(255, 192, 203, 0.462);

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 25px;
  margin: 5px;
  padding: 8px;

  height: auto;
}

.box-now {
  margin: 5px;
  background-color: rgba(128, 255, 0, 0.195);
  border-radius: 25px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 22px;
  margin: 0px 0px;
  border: 8px;
  height: auto;
}

.line-buy {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
}
