.Adress {
  background-color: rgba(255, 192, 203, 0.462);
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 25px;
  margin: 5px;
  padding: 8px;
}
.addres-line {
  display: flex;
  justify-content: center;
}
.addresLine {
  word-wrap: break-word; /* Allows long words to be broken and wrap to the next line */
  overflow-wrap: break-word; /* Ensures compatibility with all browsers */
  word-break: break-all; /* Breaks the word at any character to fit it within the container */
}

.addres-line:hover {
  cursor: pointer;
}

.box-addres {
  background-color: rgba(128, 255, 0, 0.195);
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 22px;
  padding: 8px;
  margin: 0px 0px;

  width: 100%;
  box-sizing: border-box;
}
