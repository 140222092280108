.Together {
  background-color: rgba(255, 192, 203, 0.462);

  border-radius: 25px;
  margin: 5px;
  padding: 8px;
  height: auto;
}

.box {
  width: auto;
  background-color: rgba(128, 255, 0, 0.195);

  border-radius: 22px;
  padding: 8px;
  margin: 0px 0px;
}
