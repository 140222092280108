*,
html {
  margin: 0;
  padding: 0;
}

html {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

body {
  text-align: center;
  background-color: rgb(105, 182, 105);

  .bgImage {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    z-index: -1;
    object-position: bottom;
  }

  h1 {
    margin: 5px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-size: 48px;

    letter-spacing: 2px;
    font-stretch: wider;

    font-family: "Bubblegum Sans", sans-serif;
    filter: drop-shadow(0px 0px 4px #ffffff);
  }

  h2 {
    margin: 5px;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 34px;
    letter-spacing: 1px;
    font-stretch: wider;

    font-family: "Bubblegum Sans", sans-serif;
    filter: drop-shadow(0px 0px 3px #ffffff);
  }
  h3 {
    margin: 5px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 34px;
    letter-spacing: 1px;
    font-stretch: wider;

    filter: drop-shadow(0px 0px 3px #ffffff);

    font-family: "Bubblegum Sans", sans-serif;
  }
  p {
    margin: 5px;
    font-family: "Montserrat", sans-serif;
    font-weight: 450;
    font-size: 18px;
  }
}

button {
  margin: 5px;
  padding-left: 6px;

  background-color: black;
  color: whitesmoke;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 10px;
  filter: drop-shadow(0px 0px 2px #ffffff);
}
button:hover {
  cursor: pointer;
  scale: 1.05;
  transition: 1s;
}

.socialIcon-inButton {
  margin: 1px;
}

.App {
  background-color: rgba(70, 112, 58, 0.301);
}

.Content {
  /* background-color: rgba(0, 128, 0, 0.406); */
  width: min(100% - 30px, 900px);
  margin-inline: auto;
  padding: 5px;
  padding-bottom: 130px;

  display: flex;
  flex-direction: column;
}

.SnackbarProviderButton {
  background-color: transparent;
  border: none;
  color: whitesmoke;
  filter: none;
}
.SnackbarProviderButton:hover {
  cursor: pointer;
}

@media only screen and (max-width: 720px) {
  body {
    h1 {
      /* background-color: red; */
      font-size: 8vw;
    }
    h2 {
      /* background-color: rgb(151, 59, 122); */
      font-size: 5.5vw;
    }
    h3 {
      /* background-color: rgb(45, 175, 45); */
      font-size: 5.5vw;
    }
    p {
      /* backgound-color: red; */
      font-size: 15px;
    }
  }
}

@supports (-webkit-touch-callout: none) and (not (selector(:focus-visible))) {
  /* This targets iOS versions before iOS 15, adjust as necessary */
  .bgImage {
    display: none;
  }
}
