.Tokenomic {
  background-color: rgba(255, 192, 203, 0.462);

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  border-radius: 25px;
  margin: 5px;
  padding: 8px;
  height: auto;
}

.box-tokenomic-right {
  background-color: rgba(128, 255, 0, 0.195);
  width: 70%;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 22px;
  padding: 8px;
  margin: 1px 0px;
}

.line {
  display: flex;
}

.link {
  text-decoration: underlined;
}
.link:hover {
  cursor: pointer;
  text-decoration: underlined;
}
.gif-side-tockenomy {
  margin: 5px;
  width: 90%;
  filter: drop-shadow(-6px 7px 5px #000000);
  padding: 10px;

  object-fit: contain;
  height: auto;
}
.box-tokenomic-left {
  /* background-color: rgba(128, 255, 0, 0.195); */
  width: 30%;
  display: flex;
  justify-content: center;
}

.line-base {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 720px) {
  .gif-side-tockenomy {
    width: 90%;
    height: fit-content;
    filter: drop-shadow(-2px 2px 2px #000000);
    height: auto;
  }
}
