.Carousel {
  background-color: rgba(255, 192, 203, 0.462);

  border-radius: 25px;
  margin: 5px;
  padding: 8px;
}
.box-carusell {
  /* margin: 5px; */
  display: flex;
  flex-direction: column;
}

.box-carusell-txt {
  background-color: rgba(128, 255, 0, 0.195);

  border-radius: 22px;
  padding: 8px;
  margin: 0px 0px;
}

.carusell-img {
  display: flex;
  justify-content: space-around;
  overflow-x: scroll;
}

.line-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gif-go {
  height: 150px;
  margin-left: 2px;
  margin-right: 2px;
  filter: drop-shadow(5px 2px 4px #000000);
}
