.Header {
  background-color: rgba(255, 192, 203, 0.462);

  border-radius: 25px;
  margin: 5px;
  padding: 8px;

  display: flex;
  justify-content: center;
  height: auto;
  flex-direction: row;
}

.box-left {
  background-color: rgba(128, 255, 0, 0.195);

  border-radius: 22px;
  padding: 8px;
  margin: 1px 0px;

  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box-right {
  /* background-color: rgba(128, 255, 0, 0.195); */
  margin: 5px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 22px;
  height: auto;
}

.gif-side-header {
  width: 90%;
  filter: drop-shadow(10px 5px 5px #000000);

  height: auto;
  object-fit: contain;
}

@media only screen and (max-width: 720px) {
  .gif-side {
    width: 90%;
    height: fit-content;
    filter: drop-shadow(2px 2px 2px #000000);
  }
}
