/* ===================== FOOTER ============================== */
.Footer-image {
  /* background-image: url("./Media/PNG/grass.png");
    filter: drop-shadow(3px 5px 2px #000000);
  
    background-size: contain;
    background-position: bottom;
    background-repeat: repeat-x; */
  width: 100%;
  height: 100px;

  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
.Footer {
  /* background-color: rgba(0, 0, 255, 0.276); */

  width: 100%;
  height: 100px;

  display: flex;
  justify-content: center;

  background-image: url("../Media/PNG/grass.png");
  filter: drop-shadow(3px 5px 2px #000000);

  background-size: contain;
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: auto 100%;
}

.Footer-icons {
  display: flex;
  justify-content: center;
  align-items: end;

  background-color: transparent;
  width: min(100% - 30px, 900px);
}
.socialIcon {
  height: 30px;
  width: 30px;
  margin: 20px;
  filter: drop-shadow(0px 0px 4px #ffffff);
}
.socialIcon:hover {
  cursor: pointer;
  scale: 1.2;
  transition: 1s;
}

.witanko-gif {
  /* background-color: rgba(0, 0, 0, 0.167); */
  position: absolute;
  bottom: 0px;
  height: 200px;
  left: 60%;
  filter: drop-shadow(10px 5px 5px #000000);
}

@media only screen and (max-width: 720px) {
  .witanko-gif {
    /* background-color: rgba(0, 0, 0, 0.167); */
    position: absolute;
    bottom: 0px;
    height: 200px;
    left: 57%;
    filter: drop-shadow(10px 5px 5px #000000);
  }
}
