.Next {
  background-color: rgba(255, 192, 203, 0.462);

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 25px;
  margin: 5px;
  padding: 8px;

  height: auto;
}
.box-next-left {
  /* background-color: rgba(128, 255, 0, 0.195); */
  margin: 5px;
  width: 30%;
  display: flex;
  justify-content: center;
  height: auto;
}

.box-next-right {
  background-color: rgba(128, 255, 0, 0.195);
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 22px;
  padding: 8px;
  margin: 0px 0px;
  box-sizing: border-box;
}
.gif-next {
  width: 90%;
  filter: drop-shadow(-3px 3px 4px #000000);

  object-fit: contain;
  height: auto;
}
@media only screen and (max-width: 720px) {
  .gif-next {
    width: 90%;
    height: fit-content;
    filter: drop-shadow(-1px 2px 2x #000000);
    height: auto;
  }
}
